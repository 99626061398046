<template>
  <AuthComponent
    :items="items"
    :type="'register'"
    :item-tab="tab"
    :phone-number="phoneNumber"
    :first-name="firstName"
    :last-name="lastName"
    @perform-main-action="nextStep"
    @phone-change="phoneChange"
    @full-code="codeChange"
    @first-name-changed="firstNameChanged"
    @last-name-changed="lastNameChanged"
    @credentials-changed="credentialsChanged"
    @social-register="socialRegisterChange"
    @goBackToRegister="goBackTab"
    @goBackToLogin="goBackTab"
  >
    <template #agree-emails>
      <v-checkbox
        v-model="agreeEmails"
        :ripple="false"
        hide-details
        class="mt-0 mb-4"
      >
        <template #label>
          <div>
            <span class="text text-l-small-regular accept-email">I want to receive emails from Acapedia</span>
          </div>
        </template>
      </v-checkbox>
    </template>
    <template #forgot-password>
      <v-col
        v-show="tab == enumOptions.mainView"
        class="lg-8"
      >
        <span
          class="text text-l-large-regular-link primary--text clickable mb-4"
          @click="forgotPassword"
        >Forgot password</span>
      </v-col>
    </template>
  </AuthComponent>
</template>

<script>
  import AuthComponent from '@/components/auth/AuthComponent';
  import ValidatorComponent from '@/components/common/ValidatorComponent';
  import UtilitiesComponent from '@/components/common/UtilitiesComponent';
  import { mapActions } from 'vuex';
  export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { AuthComponent, UtilitiesComponent },
    data: () => ({
      rules: ValidatorComponent.data().rules,
      tab: 0,
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      fullCode: '',
      isVerify: false,
      googleId: null,
      referralCode: null,
      blockSubmit: false,
      blockStep: false,
      agreeEmails: false,
      enumOptions: { mainView: 0, forgotPassword: 4 },
      items: [
        {
          tab: 0,
          title: "Welcome",
          content:"Already have an account?",
          slot: "email-password",
          btnPrimaryText: "Sign up",
          btnOptionalText: "Login",
        },
        {
          tab: 1,
          title: "We want to know more about you",
          content:"This information needs to be accurate",
          slot: "legal-name",
          btnPrimaryText: "Continue",
          btnOptionalText: "Go back",
        },
        {
          tab: 2,
          title: "Verify your phone number",
          content:"We will send you a code via SMS",
          slot: "phone",
          btnPrimaryText: "Send SMS",
          btnOptionalText: "Go back",
        },
        {
          tab: 3,
          title: 'Confirm code',
          content: "Enter the code you received via SMS to ******",
          slot: 'confirm-code',
          btnPrimaryText: '',
          btnOptionalText: 'Continue'
        },
        {
          tab: 4,
          title: "Forgot password",
          content:
            "We will send you an email to reset your password",
          slot: "password-reset",
          btnPrimaryText: "Confirm",
          btnOptionalText: ''
        }
      ],
      phoneUtilities: UtilitiesComponent.data().phone,
      NO_DIGITS: 0,
      MAX_DIGITS: 6
    }),
    mounted() {
      this.referralCode = this.$route.query.referralCode;
    },
    methods:{
      ...mapActions('auth', ['emailExists', 'sendConfirmationCode', 'verifyPhoneNumber', 'register']),
      submitForm() {
        let registerParams = { email: this.email, password: this.password, phoneNumber: this.phoneNumber, firstName: this.firstName, lastName: this.lastName };

        if(this.googleId) {
          registerParams = {...registerParams, googleId: this.googleId, firstName: this.firstName, lastName: this.lastName };
        }

        if(this.referralCode) {
          registerParams = { ...registerParams, referralCode: this.referralCode };
        }

        this.register(registerParams).then( () => {
          this.$router.push({name: 'home'});
        }).catch( error => {
          this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Unexpected error'});
        });
      },
      async nextStep(form, isSocialRegister) {
        if(!this.blockStep) {
          this.blockStep = true;
          switch (this.tab) {
            case 0:
              this.validateEmailPasswordStep(form.email, isSocialRegister);
              break;
            case 1:
              await this.validateLegalNamesStep(form);
              break;
            case 2:
              await this.validatePhoneStep();
              break;
            case 3:
              await this.validateCodeStep();
              break;
          }
        }
      },
      async validateLegalNamesStep(form) {
        const validateFirstName = ValidatorComponent.data().rules.names(this.firstName);
        const validateLastName = ValidatorComponent.data().rules.names(this.lastName);

        this.blockStep = false;
        if(validateFirstName===true && validateLastName===true) { this.tab++; return; }

        this.$toasted.clear();
        this.$toasted.global.showError({message: 'Invalid fields, please check again'});

      },
      async validateEmailPasswordStep(email, isSocialRegister) {

        const isValidEmailField = ValidatorComponent.data().rules.email(this.email) === true;
        const isValidPasswordField = ValidatorComponent.data().rules.password(this.password) === true;

        if (!isValidEmailField || (!isValidPasswordField && !isSocialRegister)) {
          this.blockStep = false;
          return;
        }

        const isEmailAvailable = !(await this.emailExists({ email: this.email})).data.exists;

        if (isEmailAvailable) {
          this.tab++;
        }

        this.blockStep = false;
      },
      async validatePhoneStep() {
        const validateField = ValidatorComponent.data().rules.phoneNumber(this.phoneNumber);
        if ( validateField === true ) {

          this.sendConfirmationCode({phone_number: this.phoneNumber}).then( response => {
            this.$toasted.global.showSuccess({message: response.data.message});
            this.isVerify = false;
            this.phoneNumberChanges = false;
            this.tab++;
          }).catch( error => {
            this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Phone verification failed, please try again'});
          });

        } else {
          this.$toasted.global.showError({message: 'Invalid field, please check again'});
        }
        this.blockStep = false;
      },
      async validateCodeStep() {
        const isValidPhoneNumber = ValidatorComponent.data().rules.phoneNumber(this.phoneNumber) === true;

        if (!isValidPhoneNumber) {
          this.$toasted.global.showError({message: 'Invalid field, please check again'});
          this.blockStep = false;

          return;
        }

        if (this.fullCode.length === this.NO_DIGITS) {
          this.$toasted.global.showError({message: 'You did not enter a verification code'});
          this.blockStep = false;

          return;
        }

        if (this.fullCode.length !== this.MAX_DIGITS) {
          this.$toasted.global.showError({message: 'Please enter a complete verification code'});
          this.blockStep = false;

          return;
        }

        try {
          await this.verifyPhoneNumber({ phone_number: this.phoneNumber, code: this.fullCode });
          this.$toasted.global.showSuccess({message: 'Phone number verified successfully'});
          this.isVerify = true;
          this.submitForm();
        } catch (error) {
          this.$toasted.global.showError({message: error.response.data ?  error.response.data.message : 'Code verification failed, please try again'});
        } finally {
          this.blockStep = false;
        }
      },
      checkVerifyAttempts() {
        this.verifyAttempts++;
        if(this.verifyAttempts >= this.VerifyMaxAttempts){
          this.suggestResendCode = true;
        }
      },
      changeNumber(e) {
        e.preventDefault();
        this.tab = 1;
        this.phoneNumberChanges = true;
        this.blockStep = false;
      },
      phoneChange(newPhone) {
        this.phoneNumber = newPhone;
      },
      codeChange(newCode) {
        this.fullCode = newCode;
      },
      firstNameChanged(newForm) {
        this.firstName = newForm.firstName;
      },
      lastNameChanged(newForm) {
        this.lastName = newForm.lastName;
      },
      credentialsChanged(newForm) {
        this.email = newForm.email;
        this.password = newForm.password;
      },
      socialRegisterChange(socialiteUser) {
        this.googleId = socialiteUser.googleId;
        this.firstName = socialiteUser.firstName;
        this.lastName = socialiteUser.lastName;
      },
      goBackTab(data) {
        this.tab = data;
      },
      forgotPassword() {
        this.tab = this.enumOptions.forgotPassword;
      },
    },
  }
</script>
<style scoped>
  .accept-email {
    color: var(--v-grey-darken4) !important ;
  }

</style>